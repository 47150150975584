import { FEATURES } from 'core/helpers/account/account-features'

////////////////
/////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////
/////////

import CoreLayouts from 'core/editor/managers/layouts'

class Layouts extends CoreLayouts {
  updateFeatures (featureUpdates) {
    featureUpdates.features.forEach(feat => {
      if (feat.name) {
        // merge
        const newFeature = (this.features.hasOwnProperty(feat.name))
          ? { ...this.features[feat.name], ...feat }
          : { ...feat }

        // Update properties.
        newFeature.properties = this.convertProps(feat.settings.properties)

        this.features[feat.name] = newFeature
      }
    })
    super.updateFeatures(featureUpdates)
  }

  getLayoutForNewSection (canAddLayout) {
    const store = Store().getState()

    const { accountFeatures } = store.api
    const { accountType } = store.auth

    return super.getLayoutForNewSection(layout => {
      const doesNotHaveFeature = layout.metadata.accountFeatureRequirement && accountFeatures[layout.metadata.accountFeatureRequirement] !== true

      const isStoreLayout = layout.metadata.accountFeatureRequirement === FEATURES.STORE
      const isBlogLayout = layout.metadata.accountFeatureRequirement === FEATURES.BLOG
      const isBookingLayout = layout.metadata.accountFeatureRequirement === FEATURES.BOOKING
      const isValidAccountType = !isSP(accountType) && !isCTCT(accountType)

      if (isStoreLayout) {
        if (!isHostedBrand() && (doesNotHaveFeature || !isValidAccountType)) {
          return false
        }
      } else if (isBlogLayout) {
        if (LimitationsManager.isBlogLimited()) {
          return false
        }
      } else if (isBookingLayout) {
        if (LimitationsManager.isBookingLimited() || !isValidAccountType) {
          return false
        }
      } else {
        if (doesNotHaveFeature) {
          return false
        }
      }
      return layout.metadata.canAddAsSection !== false && canAddLayout(layout)
    })
  }
}
export default Layouts
