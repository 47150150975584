import BaseControl from '../base-control'

import $ from 'jquery'

import Layout from '../templates/background-readonly-control.tpl'

import '../style/background-control.scss'

class BackgroundControl extends BaseControl {
  constructor (controller, model) {
    super(controller, () => Layout, model, 'background')
    this.lazyImageSelector = '.kv-background-inner, video'
  }

  bindLayout (element, data) {
    super.bindLayout(element, data)
    if (this.model.offset) {
      element.style.marginBottom = `-${this.model.offset}px`
    }

    // Fix for iOS which doesn't support fixed
    if (data.fixed === true) {
      const iOS =
        typeof window !== 'undefined' &&
        window.navigator &&
        !!window.navigator.platform &&
        /iPad|iPhone|iPod/.test(window.navigator.platform)
      if (iOS === true) {
        element.querySelector('.kv-background-inner').style.backgroundAttachment = ''
      }
    }

    if (data.parallax === true) {
      const element = document.querySelector('.content-wrapper') || document.documentElement
      setTimeout(() => {
        const $parallax = $('.kv-parallax-container', element)
        const total = window.innerHeight

        function parallax () {
          const scrolled = element.getBoundingClientRect().top

          const movement = (scrolled / total) * (0.2 / 1.4)
          $parallax.css('transform', `scale(1.4) translate3d(0, ${movement * 100}%, 0)`)
        }
        if (element.scrollFunction) {
          element.removeEventListener('scroll', element.scrollFunction)
        }
        element.scrollFunction = (e) => {
          parallax()
        }
        element.addEventListener('scroll', element.scrollFunction)
      }, 0)
    }

    if (data.contain === true) {
      element.querySelector('.kv-background-inner').style.backgroundSize = 'contain'
    }

    // if (data.hasOwnProperty('decoration')) {
    //   this.model.decoration = {
    //     ...data.decoration,
    //     layerOffset: 25,
    //     decorationHeight: 200
    //   }
    // } else {
    //   this.model.decoration = {
    //     type: 'straight',
    //     seed: 1,
    //     layers: 1,
    //     layerOffset: 25,
    //     decorationHeight: 200,
    //     randomizeLayers: true
    //   }
    // }

    // Decoration logic for rendering..
    // TODO: @dvandervlag @pkiers This probably needs to live inside of the editable control instead of readonly..
    // defer(() => {
    //   if (
    //     element.parentElement && (
    //       (this.model.sectionHeight !== element.parentElement.clientHeight) ||
    //       (this.model.sectionWidth !== element.parentElement.clientWidth)
    //     )
    //   ) {
    //     this.model.sectionHeight = element.parentElement.clientHeight
    //     this.model.sectionWidth = element.parentElement.clientWidth

    //     this.requestRender()
    //   }

    //   if (this.model.decoration) {
    //     const offset = this.getDecorationOffset()
    //     // console.log('Offset', offset)

    //     // Update current sections' content controller.
    //     const contentController = this.controller.parentController.getControlOfType('content')
    //     const previousSectionController = this.controller.parentController.getPreviousSectionController()
    //     const previousSectionBackgroundController = previousSectionController?.controller.getControlOfType('background')

    //     // console.log('previousSectionBackgroundController?.model.offset !== offset', previousSectionBackgroundController?.model.offset !== offset, previousSectionBackgroundController?.model.offset, offset)
    //     if (previousSectionBackgroundController?.model.offset !== offset || contentController.model.offset !== offset) {
    //       // Update the previous sections' background controller
    //       if (previousSectionBackgroundController) {
    //         previousSectionBackgroundController.controller.editorContext.updatePropertyInstant(previousSectionBackgroundController.model, 'offset', offset)
    //         previousSectionController.requestRender()

    //         // We don't want to update the header sections.. EVER
    //         contentController.controller.editorContext.updatePropertyInstant(contentController.model, 'offset', offset)
    //         if (this.model.offset) {
    //           contentController.controller.editorContext.updatePropertyInstant(contentController.model, 'contentTopOffset', offset - this.model.offset)
    //         }
    //         contentController.requestRender()
    //       }
    //     }
    //   }
    // })
  }

  getDecorationOffset = () => {
    const { layers, layerOffset, decorationHeight, type } = this.model.decoration

    // TODO: This should be added if you switch to straight..
    if (type === 'straight') {
      return 0
    }
    return ((layers - 1) * layerOffset) + decorationHeight
  }

  getChildControls () {}
}

export default BackgroundControl
