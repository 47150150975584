import CoreSectionControl from 'core/editor/controls/section-control'

import Layout from './templates/section-control.tpl'

////////////////
/////////////////////////////////////
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////

////////////////////
/////////

class SectionControl extends CoreSectionControl {
  constructor (controller, model) {
    super(controller, model, Layout)
  }

  updateKVControls () {
    function setVisible (el, visible) {
      if (el) {
        el.style.display = visible ? '' : 'none'
      }
    }
    const allSectionModels = this.controller.getPageController().getAllSections()

    const isHeader = allSectionModels[0] === this.model
    const isHeaderSibling = allSectionModels[1] === this.model
    const isFooterSibling = allSectionModels[allSectionModels.length - 1] === this.model
    const isFooter = allSectionModels[allSectionModels.length - 1] === this.model
    const isMandatory = !FeatureDependanciesLogic.canDeleteSection(this.model, this.controller.getPageController().model)

    setVisible(this.removeButton, !isHeader && !isFooter && !isMandatory)
    setVisible(this.upButton, !isHeader && !isFooter && !isHeaderSibling)
    setVisible(this.downButton, !isHeader && !isFooter && !isFooterSibling)
  }
}
export default SectionControl
