import each from 'lodash/each'
import debounce from 'lodash/debounce'

import StringStyleSheet from './string-style-sheet'
import LiveStyleSheet from 'editor/helpers/style-loader/live-style-sheet'

let currentZoomFactor = null
let tempSwapStyleSheets
let stylesheets = {}

export const updateInternal = (zoom, includeTemp) => {
  currentZoomFactor = zoom

  each(stylesheets, sheet => {
    sheet.updateMediaQueriesWidths(zoom)
  })
  if (includeTemp) {
    each(tempSwapStyleSheets, sheet => {
      sheet.updateMediaQueriesWidths(zoom)
    })
  }

  // some window code to commiunicate with navigation feature
  window._zoomFactor = zoom.zoomFactor
  if (window._zoomUpdateEvents) {
    window._zoomUpdateEvents.forEach(func => func())
  }
}
const updateMediaQueriesWidthsDebounce = debounce(updateInternal, 200)

export const getCssClass = layout => {
  return layout.id
}

class StyleLoader {
  static setCollectStyleSheetsForPublish (value) {
    // keep references of old stylesheets for publishing from the editor
    if (value) {
      tempSwapStyleSheets = stylesheets
      stylesheets = {}
    } else {
      stylesheets = tempSwapStyleSheets
      tempSwapStyleSheets = null
    }
    this.collectStyleSheetsForPublish = value
  }

  static getAllStyleSheetsForPublish () {
    let result = ''
    const imports = []
    each(stylesheets, sheet => {
      result += sheet.getCss() + '\n'
    })
    each(stylesheets, sheet => {
      sheet.getImports().forEach(imp => {
        if (!imports.includes(imp)) {
          imports.push(imp)
        }
      })
    })
    return imports.join('\n') + result
  }

  static clearAllStyleSheetsForPublish () {
    tempSwapStyleSheets = stylesheets
    stylesheets = {}
  }

  static updateMediaQueriesWidths (currentWindowWidth, editorWidth, zoomFactor) {
    if (editorWidth < 400) {
      const deltaWidth = currentWindowWidth - editorWidth
      updateMediaQueriesWidthsDebounce({
        deltaWidth: deltaWidth,
        zoomFactor
      })
    } else {
      updateMediaQueriesWidthsDebounce({
        deltaWidth: 0,
        zoomFactor
      })
    }
  }

  static setCss (stylesheetKey, css, isBaseStyling, layout, colorModel, stylesheetCollector) {
    stylesheetCollector = stylesheetCollector || stylesheets
    const Class = this.collectStyleSheetsForPublish ? StringStyleSheet : LiveStyleSheet
    stylesheetCollector[stylesheetKey] = new Class(stylesheetKey, css, isBaseStyling, layout, colorModel, currentZoomFactor)
  }

  static removeCss (stylesheetKey) {
    if (stylesheets[stylesheetKey]) {
      stylesheets[stylesheetKey].dispose()
      delete stylesheets[stylesheetKey]
    }
  }

  static loadOrUpdateLayout (layout, colorModel) {
    const stylesheetKey = layout.id
    const stylesheetCollector = stylesheets[stylesheetKey] ? stylesheets : colorModel.stylesheetCollector || stylesheets

    if (!stylesheetCollector[stylesheetKey]) {
      this.setCss(stylesheetKey, layout.css, layout.id === 'base', layout, colorModel, stylesheetCollector)
    } else {
      stylesheetCollector[stylesheetKey].parseColors(colorModel)
    }
  }
}
export default StyleLoader
