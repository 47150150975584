var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " kv-edit-mode";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + container.escapeExpression(__default(require("../../../../../services/app-designer/helpers/control.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"key")),(data && lookupProperty(data,"index")),depth0,{"name":"control","hash":{},"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":32}}}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<script>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"scriptInject") : depth0), depth0))
    + "</script>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"kv-site kv-main"
    + ((stack1 = __default(require("../../../../../services/app-designer/helpers/editable.js")).call(alias1,{"name":"editable","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":27},"end":{"line":1,"column":66}}})) != null ? stack1 : "")
    + "\" tabindex=\"-1\">\n  <a href=\"#ada-tab-anchor\" class=\"skip-link\" data-tag=\"ada-skip-link\"></a>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"_children") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":5,"column":11}}})) != null ? stack1 : "")
    + "  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"scriptInject") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":2},"end":{"line":6,"column":62}}})) != null ? stack1 : "")
    + "\n</div>";
},"useData":true});