import Store from '@eig-builder/core-utils/store'
import get from 'lodash/get'
import uniq from 'lodash/uniq'

const AllCategory = 'All'

function makePath (path, next) {
  return path ? `${path}.${next}` : next
}

function throwMsg (errorMsg) {
  throw new Error(`error in DataDefintion: ${errorMsg}`)
}

// if the translation key is like 'list.some.title', and the path = 'list' return the 'some'
function getDeeperProp (translationKey, path) {
  const remaining = translationKey.substr(path.length + 1)
  const dotIndex = remaining.indexOf('.')
  if (dotIndex < 0) {
    return remaining
  }
  return remaining.substr(0, dotIndex)
}

class DemoDataManager {
  constructor () {
    this.rndImageIndex = parseInt(Math.random() * 2 ** 32)
  }

  getTranslations () {
    const { adminParams } = Store().getState().designer.main
    if (adminParams) {
      if (!this.mergedDemoData) {
        const data = this.getAllAdminDemoDataTranslations()
        this.mergedDemoData = { ...data[AllCategory] }
        if (data[adminParams.verticalName]) {
          Object.entries(data[adminParams.verticalName]).forEach(([k, v]) => (this.mergedDemoData[k] = v))
        }
      }
      return this.mergedDemoData
    }
    return get(Store().getState(), 'api.demoData.response.categories') || {}
  }

  getGlobalBinding (state) {
    const { designer, campaign } = state

    const globalBindingCurrent = get(designer, 'edit.tree.globalBinding')
    if (globalBindingCurrent) {
      return globalBindingCurrent
    }

    const globalBindingSiteForCampaign = get(campaign, 'siteForCampaign.response.description.globalBinding')
    return globalBindingSiteForCampaign
  }

  getImages () {
    const { adminParams } = Store().getState().designer.main
    if (adminParams) {
      return [{ url: '//placekitten.com/500/500' }]
    } else {
      return get(Store().getState(), 'api.demoData.response.images') || []
    }
  }

  clearNoneTextFieldsForBinding (category, binding) {
    const dataDef = this.getDataDef()
    const typeObj = dataDef.categories[category]
    Object.keys(binding).forEach(prop => {
      const typeValue = typeObj[prop]
      // this is sort of a hack to merge the template data with the demodata/datadefinition
      if (typeValue && ((typeof typeValue !== 'string') || typeValue.indexOf(':') >= 0)) {
        delete binding[prop]
      }
    })
  }

  getAllAdminDemoDataTranslations () {
    return Store().getState().api.adminDemoData
  }

  getDataDef () {
    return Store().getState().api.dataDefinition
  }

  getDataType (name) {
    const { types } = this.getDataDef()
    name = name.toLowerCase()
    if (!types || !types[name]) {
      throwMsg(`type not found: ${name}`)
    }

    return types[name]
  }

  getTranslationKey = (path, _default) => {
    if (_default) {
      // save default values when in admin modus
      const data = this.getAllAdminDemoDataTranslations()
      if (data && !data[AllCategory][path]) {
        data[AllCategory][path] = _default
      }
    }

    return this.getTranslations()[path] || _default || ''
  }

  getTranslationKeyDeeperProps = path => {
    const subProps = Object.keys(this.getTranslations())
      .filter(key => key.startsWith(path))
      .map(key => getDeeperProp(key, path))
      .filter(Boolean)

    return uniq(subProps)
  }

  getTranslationKeyArrayLength = path => {
    function getArrayIterationIndex (translationKey) {
      const number = parseInt(getDeeperProp(translationKey, path))
      return isNaN(number) ? 0 : number
    }
    const indexes = Object.keys(this.getTranslations())
      .filter(key => key.startsWith(path))
      .map(getArrayIterationIndex)

    if (!indexes.length) {
      return null
    }
    return Math.max.apply(null, indexes) + 1
  }

  // iterate through the types from the data definition. Resolve all the sub types and translation keys.
  // returns a new filled model with the data from the data definition filled with the translations
  makeModel (typeObj, path, onRequestTranslationKey, onGetArrayLength, onGetDeeperProps) {
    if (typeof typeObj !== 'object' || typeObj === null) {
      throwMsg('expected object')
    }

    const getValue = (value, propName) => {
      const localPath = makePath(path, propName)
      if (typeof value === 'string') {
        if (!value) {
          return onRequestTranslationKey(localPath)
        }

        const indexOf = value.indexOf(':')
        if (indexOf < 0) {
          // console.error(`${localPath} has invalud value: ${value}`)
          return value // try not to break
        }
        const xvalueSplit = [value.substr(0, indexOf), value.substr(indexOf + 1)]
        switch (xvalueSplit[0]) {
          case 'translate': {
            // translate with a default value :)
            return onRequestTranslationKey(localPath, xvalueSplit[1])
          }
          case 'value': {
            return xvalueSplit[1]
          }
          case 'type': {
            return this.makeModel(this.getDataType(xvalueSplit[1]), localPath, onRequestTranslationKey, onGetArrayLength, onGetDeeperProps)
          }
          case 'unsplash-image': {
            if (this.pickImage) {
              return this.pickImage().value
            }
            // the type unsplash-image is not really used because this value will be overridden in other parts of the code that inject unsplash images with logic like (if propName===image) ..., the goal this type is just to prevent that this property ends-up in the translation tool.
            return '//placekitten.com/500/500'
          }
          case 'image': {
            // return {
            //   value: '//placekitten.com/500/500'
            // }

            if (this.pickImage) {
              return this.pickImage()
            }
            const images = get(Store().getState(), 'designer.api.demoData.response.images')
            let value
            if (images) {
              this.rndImageIndex = (this.rndImageIndex + 1) % images.length
              value = images[this.rndImageIndex]
            } else {
              value = '//placekitten.com/500/500'
            }
            return {
              value
            }
          }
          case 'button': {
            const link = get(Store().getState(), 'campaign.freeDomainLink') || ''
            return {
              title: onRequestTranslationKey(localPath + '.title') || xvalueSplit[1],
              href: `https://${link}`,
              shouldOpenInTab: true,
              buttonClass: 'button-',
              colorClass: 'primary',
              textStyle: {
                'font-size': 1
              }
            }
          }
          case 'logo': {
            // return {
            //   value: 'https://images.unsplash.com/photo-1503736334956-4c8f8e92946d?ixlib=rb-1.2.1&auto=format&fit=crop&w=2720&q=80'
            // }
            const state = Store().getState()
            const global = this.getGlobalBinding(state)
            return (global && global.logo && global.logo.value) ? global.logo : {
              // value: 'https://i.imgur.com/jjcnq7l.png'
              value: 'https://storage.googleapis.com/wzreponsiveeditor-static-latest/images/mail/logo_black_square.png',
              valueForDarkBacks: 'https://storage.googleapis.com/wzreponsiveeditor-static-latest/images/mail/logo_white_square.png'
            }
          }
          case 'address': {
            const state = Store().getState()
            const global = this.getGlobalBinding(state) || {}

            const address = global.address || {}
            const addressMap = {
              country: address.country || 'USA',
              state: address.state || 'Massachusetts',
              city: address.city || 'Burlington',
              street: address.street || '10 Corporate Drive',
              zip: address.zip || 'MA 01803',
              phone: global.phone || '0123456789',
              company: global.companyName,
              email: global.email || get(state, 'auth.userEmail'),
              name: get(state, 'auth.userFullName'),
              role: global.companyName ? `CEO at ${global.companyName}` : 'Function at Company name'
            }

            if (xvalueSplit[1] === 'address') {
              return [addressMap.street, addressMap.city, addressMap.zip, address.country].filter(Boolean).join(', ')
            }
            return addressMap[xvalueSplit[1]]
          }
          case 'date': {
            if (xvalueSplit[1] === 'currentYear') {
              return new Date().getFullYear()
            }
            if (xvalueSplit[1] === 'countdown') {
              return new Date(new Date().getTime() + 10 * 3600 * 1000) + ''
            }
            break
          }
          case 'pageLink': {
            const pages = get(Store().getState(), 'designer.edit.tree.pages')
            if (!pages) {
              // pages = null from onboarding
              // we always have a page with contact id from the templates.
              return `page:contact`
            } else {
              // we come here from add section (not onboarding)
              const notMainPage = pages.find(i => !i.mainPage) || pages[1]
              return notMainPage ? `page:${notMainPage.id}` : '' // empty link for single pager
            }
          }
          default:
            return value
        }
      } else if (typeof value === 'object') {
        if (value === null) {
          return null
        }
        return this.makeModel(value, localPath, onRequestTranslationKey, onGetArrayLength, onGetDeeperProps)
      } else {
        return value
      }
    }

    let result
    if (typeObj.constructor === Array) {
      let length = onGetArrayLength(path)
      if (length === null) {
        length = typeObj.length
      }
      result = []
      for (let i = 0; i < length; i++) {
        result.push(getValue(typeObj[i < typeObj.length ? i : 0], i))
      }
    } else {
      result = {}
      Object.keys(typeObj).forEach(propName => {
        result[propName] = getValue(typeObj[propName], propName)
      })

      const extraProps = onGetDeeperProps(path)
      extraProps.forEach(prop => {
        if (!typeObj[prop]) {
          result[prop] = onRequestTranslationKey(makePath(path, prop))
        }
      })
    }
    return result
  }

  getCategories () {
    const dataDef = this.getDataDef()
    if (dataDef) {
      return Object.keys(dataDef.categories)
    }
    return []
  }

  createSectionModelForCategory (categoryName) {
    const dataDef = this.getDataDef()
    const typeObj = dataDef.categories[categoryName]

    return this.makeModel(typeObj, categoryName, this.getTranslationKey, this.getTranslationKeyArrayLength, this.getTranslationKeyDeeperProps)
  }

  makeDemoDataModel (pickImage) {
    this.pickImage = pickImage
    const result = {}
    this.getCategories().forEach(cat => {
      result[cat] = this.createSectionModelForCategory(cat)
    })
    return result
  }

  getTranslationsFromSite (site) {
    const translationKeysInSite = {}

    const collectTranslations = (demoDataCatergory, dataObj) => {
      const dataDef = this.getDataDef()
      const typeObj = dataDef.categories[demoDataCatergory]

      function getPath (str) {
        const dotIndex = str.indexOf('.')
        if (dotIndex > -1) {
          // strip category
          return get(dataObj, str.substr(dotIndex + 1))
        }
        return dataObj
      }

      function onGetTranslationValue (path, defaultValue) {
        // use the get translation value callback to know that this is a translation key that we should store
        const data = getPath(path)
        translationKeysInSite[path] = data === undefined ? defaultValue : data
      }

      function onGetArrayLength (path) {
        const data = getPath(path)
        // assert data is an array
        return data ? data.length : 0
      }

      function onGetDeeperProps (path) {
        const data = getPath(path)
        return (data && data.adminTextFields) || [] // collected from the initialization of the inline text editors
      }

      if (typeObj) {
        this.makeModel(typeObj, demoDataCatergory, onGetTranslationValue, onGetArrayLength, onGetDeeperProps)
      }
    }

    const sections = site.pages[0].sections
    sections.forEach(section => {
      collectTranslations(section.category, section.binding)
    })
    // collectTranslations('globalBinding', site.globalBinding)
    return translationKeysInSite
  }

  getAllDemoDataToSave (site) {
    const fromSite = this.getTranslationsFromSite(site)

    const allVerticals = this.getAllAdminDemoDataTranslations()
    const { adminParams } = Store().getState().designer.main

    if (adminParams.verticalName === AllCategory) {
      allVerticals[AllCategory] = fromSite
      for (const key in allVerticals) {
        if (key !== AllCategory) {
          Object.keys(allVerticals).forEach(otherKey => {
            // delete all keys in other verticals
            if (!fromSite[otherKey]) {
              allVerticals[key][otherKey] = ''
            }
          })
        }
      }
    } else {
      const all = allVerticals[AllCategory]
      if (!allVerticals[adminParams.verticalName]) {
        allVerticals[adminParams.verticalName] = {}
      }
      const vertical = allVerticals[adminParams.verticalName]
      for (const key in fromSite) {
        const val = fromSite[key]
        if (all[key] !== val) {
          vertical[key] = fromSite[key]
        } else {
          vertical[key] = ''
        }
      }
    }

    return allVerticals
  }
}

export default new DemoDataManager()
