import { api, siteNames } from '@eig-builder/core-utils/helpers/url-helper'

export const contactsApi = (url, useCache = true, returnType = 'json') => ({
  url: api(`v1.0/${url}`, 'contacts'),
  useCache,
  returnType
})

// ce-api has been renamed to emailApi
export const emailApi = (url, useCache = true, returnType = 'json') => ({
  url: api(`v1.0/${url}`, 'email-marketing'),
  useCache,
  returnType
})

export const accountsApi = (url, useCache = true, returnType = 'json') => ({
  url: api(`v1.0/${url}`, 'accounts'),
  useCache,
  returnType
})

// Connected Business
const CONNECTED_BUSINESS_PREFIX = 'connected_businesses'
export const contactsDisclaimer = (siteId) => contactsApi(`${siteId}/${CONNECTED_BUSINESS_PREFIX}/contacts_disclaimer`)

export const reApiUrl = (url, param) => api(`v1.0/${url}`, 'express-editor', param)

export const reApiV2Url = (url, param) => api(`v2.0/${url}`, 'express-editor', param)

export const cpApiUrl = (url, param) => api(`v1.0/${url}`, 'control-panel', param)

// TODO: @dvandervlag Replace by emailApi when we are ready to use the fetchHooks.
export const ceApiUrl = (url, param) => api(`v1.0/${url}`, 'email-marketing', param)

export const eventsUrl = (url, param) => api(`v1.0/${url}`, siteNames.EVENTS, param)

export const storageUrl = (url, param) => api(`v1.0/${url}`, 'storage', param)

export const blogViewerUrl = (url) => api(`v1.0/${url}`, siteNames.BLOG_VIEWER_API)

export const logoApiUrl = (url, param) => api(`v1.0/${url}`, 'logos', param)

export const billingApiUrl = (url, param) => api(`v1.0/${url}`, 'billing', param)

// Crawl URL
export const crawlrUrl = (path, param) => reApiUrl(`onboarding/crawl/${path}`, param)
export const crawlStatusUrl = (path, param) => reApiUrl(`onboarding/${path}`, param)

// Account form
export const accountSettingsForm = (siteId) => emailApi(`${siteId}/account/form`)

/// / Accounts section
export const accountStatus = siteId => emailApi(`${siteId}/account/status`)

/// / Campaigns section
const CAMPAIGNS_PREFIX = 'campaigns'
export const campaignSpecific = (siteId, campaignId) => emailApi(`${siteId}/${CAMPAIGNS_PREFIX}/${campaignId}`)
export const campaignSpecificForm = (siteId, campaignId) => emailApi(`${siteId}/${CAMPAIGNS_PREFIX}/${campaignId}/edit/form`)
export const campaignScreenshot = (siteId, campaignId) => emailApi(`${siteId}/${CAMPAIGNS_PREFIX}/${campaignId}/screenshot`)
export const campaignSchedule = (siteId, campaignId) => emailApi(`${siteId}/${CAMPAIGNS_PREFIX}/${campaignId}/schedule`)
export const campaignScheduleTest = (siteId, campaignId) => emailApi(`${siteId}/${CAMPAIGNS_PREFIX}/${campaignId}/schedule/test`)
export const campaignScheduleNow = (siteId, campaignId) => emailApi(`${siteId}/${CAMPAIGNS_PREFIX}/${campaignId}/schedule/now`)
export const campaignSchedulePreview = (siteId, campaignId) => emailApi(`${siteId}/${CAMPAIGNS_PREFIX}/${campaignId}/schedule/preview`)

// CampaignSettings
export const campaignTestRecipients = (siteId, campaignId) => emailApi(`${siteId}/${CAMPAIGNS_PREFIX}/${campaignId}/settings/test_recipients`)

/// / Contacts section
export const allContacts = (siteId, useCache) => contactsApi(`${siteId}/contacts/lookup?external_id_type=1`, 'json', useCache)

/// / List section
const LISTS_PREFIX = 'lists'
export const allLists = siteId => contactsApi(`${siteId}/${LISTS_PREFIX}/lookup?external_id_type=1&include_subscriber_count=true`)

/// Senders
const SENDERS_PREFIX = 'senders'
export const allSenders = siteId => emailApi(`${siteId}/${SENDERS_PREFIX}`)
export const senderResendVerifyMail = siteId => emailApi(`${siteId}/${SENDERS_PREFIX}/resend_verification_email`)

// NPS
export const hasNps = () => accountsApi(`nps/has_nps?type=4`)
export const addNps = () => accountsApi(`nps/add_nps`)

// Limitations
const LIMITATION_PREFIX = 'limitation'
export const limitations = (siteId, campaignId) => emailApi(`${siteId}/${LIMITATION_PREFIX}?campaignid=${campaignId}`)
