import GlobalProperties from 'core/helpers/global/global-properties'

const youtubeHelper = (props, property, classList) => {
  const { autoPlay, videoId, loop } = props
  const runtimeAutoplay = GlobalProperties.isRuntime ? autoPlay : 0
  const isMuted = autoPlay
  const controls = props.controls ? 1 : 0
  // Autoplay is only enabled for muted videos ( https://developers.google.com/web/updates/2017/09/autoplay-policy-changes )

  const src = loop ? `${videoId}?playlist=${videoId}` : `${videoId}?`
  return `
    <div
      data-type="video"
      data-property="${property}"
      class="${classList}"
    >
      <iframe
        src="//www.youtube.com/embed/${src}&amp;rel=0&amp;showinfo=0&amp;autoplay=${runtimeAutoplay}&amp;mute=${isMuted}&amp;loop=${loop}&amp;controls=${controls}"
        frameborder="0"
        ${isMuted && 'muted="true"'}
        allow="autoplay; encrypted-media"
        allowfullscreen></iframe>
    </div>`
}

export default youtubeHelper
