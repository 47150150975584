function calculateCoverage (section, { scrollY, windowHeight, topPadding }) {
  if (!section) {
    return 0
  }
  // the way to determin if a section needs to be selected is: currentCoverage / maxCoverage, coverage is how much of the screen in occupid by the section. if a section is longer then the screen this is 100% if a section is shorter then the screen this is sectionHeight/windoHeight*100
  const el = section
  const maxCoverage = Math.min(1, el.offsetHeight / windowHeight)
  const y = el.offsetTop + topPadding - scrollY

  let currentCoverage = y < 0 ? y + el.offsetHeight : windowHeight - y
  currentCoverage = Math.min(maxCoverage, Math.max(0, currentCoverage / windowHeight))
  return currentCoverage / maxCoverage
}

export function selectByScroll (props) {
  const { scrollY, sections, onSelectSection } = props

  // Select header when scroll to top
  if (scrollY === 0) {
    onSelectSection(sections[0])
    return
  }

  // Select footer when scroll to bottom
  const scrollContainer = document.body
  if (scrollY === (scrollContainer.scrollHeight - scrollContainer.clientHeight)) {
    const lastSection = sections[sections.length - 1]
    onSelectSection(lastSection)
    return
  }

  // Select section depending on coverage
  const currentSectionControl = props.activeSection
  const currentSectionCoverage = calculateCoverage(currentSectionControl, props)

  if (currentSectionCoverage < 0.9) {
    const currentSectionIndex = sections.indexOf(currentSectionControl, props)

    for (let i = -1; i <= 1; i += 2) {
      // look at previous and next sections
      const otherIndex = currentSectionIndex + i
      if (otherIndex >= 0 && otherIndex < sections.length) {
        const coverage = calculateCoverage(sections[otherIndex], props)

        if (
          (currentSectionCoverage < 0.9 && coverage >= 1) ||
          (currentSectionCoverage < 0.4 && coverage > currentSectionCoverage)
        ) {
          onSelectSection(sections[otherIndex])
        }
      }
    }
  }
}

export function findSectionInView (sections, props) {
  let maxCoverage = -1
  let maxSection = null
  sections.forEach(section => {
    const coverage = calculateCoverage(section, props)
    if (coverage > maxCoverage) {
      maxCoverage = coverage
      maxSection = section
    }
  })
  return maxSection
}
